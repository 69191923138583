import React, { useRef, useState, useEffect, Fragment } from "react"
import Select from "react-select"
import fetch from "isomorphic-fetch"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"
import classNames from "lib-vinomofo/utils/class-names"
import { AllMarkets } from "lib-vinomofo/contexts/market-context"
import AlignmentWrapper from "lib-vinomofo/components/alignment-wrapper"
import Link from "lib-vinomofo/components/link"
import VinomofoLogo from "lib-vinomofo/components/vinomofo-logo"
import LazyImage from "lib-vinomofo/components/lazy-image"

import tomSrc from "./tom.jpg"
import flags from "./flags"
import countries from "./countries"
import styles from "./choose-market.module.scss"

const hashToQueryString = data => {
  return Object.keys(data)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    })
    .join("&")
}

const Header = () => {
  return (
    <div className={styles.header}>
      <AlignmentWrapper>
        <a className={styles.logo} href="/">
          <VinomofoLogo />
        </a>
      </AlignmentWrapper>
    </div>
  )
}

const Footer = () => {
  return (
    <div className={styles.footer}>
      <AlignmentWrapper>
        <a className={styles.logo} href="/">
          <VinomofoLogo alternative />
        </a>
      </AlignmentWrapper>
    </div>
  )
}

const MarketButton = ({ market }) => {
  const Flag = flags[market.code]

  return (
    <Link className={styles.marketSelectorItem} link={market.href}>
      <div className={styles.flagWrapper}>
        <Flag />
      </div>
      <span>{market.name}</span>
    </Link>
  )
}

const MarketSelector = () => {
  return (
    <div className={styles.marketSelector}>
      <MarketButton market={AllMarkets.AU} />
      <MarketButton market={AllMarkets.SG} />
      <MarketButton market={AllMarkets.NZ} />
    </div>
  )
}

const EOIForm = () => {
  const [submitted, setSubmitted] = useState(false)

  return (
    <div className={styles.formWrapper}>
      {submitted ? (
        <Thanks />
      ) : (
        <FormContent onSubmit={() => setSubmitted(true)} />
      )}
    </div>
  )
}

const FormContent = ({ onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const formRef = useRef()

  const handleSubmit = event => {
    event.preventDefault()
    setIsSubmitting(true)

    const form = formRef.current
    const { name, country, email } = form
    const formName = form.getAttribute("name")

    const formData = {
      "form-name": formName,
      name: name.value,
      country: country.value,
      email: email.value,
    }

    fetch("#", {
      method: "POST",
      body: hashToQueryString(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(res => {
      if (res.ok) {
        onSubmit()
      } else {
        setIsSubmitting(false)
      }
    })
  }

  return (
    <Fragment>
      <div className={styles.formInner}>
        <h2 className={styles.title}>Want us to come to you?</h2>

        <p className={styles.description}>
          Hey Mofo! Thanks so much for dropping by. Tell us a bit about yourself
          in the form below and we'll try and bring Vinomofo to your part of the
          world - we’d love to share a wine with you.
        </p>
      </div>

      <form
        className={styles.form}
        name="market-eoi"
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <label className={styles.label}>Country</label>

        <Select
          options={countries}
          className={styles.select}
          id="country"
          name="country"
          placeholder="Select a country..."
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary25: "#f7f8fa",
              primary: "#ccc",
            },
          })}
        />

        <label className={styles.label}>Name</label>
        <input
          className={styles.input}
          id="name"
          name="name"
          type="text"
          required
        />

        <label className={styles.label}>Email</label>
        <input
          className={styles.input}
          id="email"
          name="email"
          type="email"
          required
        />

        <div className={styles.submitWrapper}>
          <button
            className={styles.submit}
            type="submit"
            disabled={isSubmitting}
            name="submit-button"
          >
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  )
}

const Thanks = () => {
  const [visible, setVisible] = useState(false)
  const classes = classNames([styles.tomWrapper, visible && styles.active])

  useEffect(() => setVisible(true), [])

  return (
    <div className={classes}>
      <h2 className={styles.tomTitle}> T.Hanks for letting us know! </h2>
      <LazyImage src={tomSrc} className={styles.tom} />
    </div>
  )
}

const breakpoints = [Breakpoint("large", [800, null])]
const ChooseMarketPage = () => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const classes = classNames([
    styles.chooseMarket,
    breakpoint === "large" && styles.large,
  ])

  return (
    <div className={classes} ref={breakpointRef}>
      <Header />
      <MarketSelector />
      <EOIForm />
      <Footer />
    </div>
  )
}

export default ChooseMarketPage
